import React, {useState} from "react"

import Layout from "../components/layout"
import SummaryBanner from "../components/summaryBanner/"
import ListItem from '../components/listItem'
//import SmartGallery from 'react-smart-gallery'
//import ReactBnbGallery from 'react-bnb-gallery';
//import 'react-bnb-gallery/dist/style.css'


import SEO from "../components/seo"

const Reviews = () => {
  const highlights = {
    'Calificación': '4.6/5.0',
  }
  const data = {
    title: 'Nombre Usuario',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam efficitur in metus vel congue. Nulla efficitur nunc at vestibulum faucibus. Integer lobortis odio sed nisl rutrum sagittis. ',
    highlights
  }
  return (
    <section style={{display: 'flex', marginTop: '2.5rem', flexDirection: 'column'}}>
      {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map((i) =>
        <div key={i} style={{margin: '0 0 1.5rem 0'}}>
          <ListItem {...data}/>
        </div>
      )}
    </section>
  )
}
const ProviderProjectDetail = () => {
  const highlights = {
    'Calificación': '4.6/5.0',
  }
  const images = [
    'https://source.unsplash.com/random/600x400',
    'https://source.unsplash.com/random/600x400',
    'https://source.unsplash.com/random/600x400',
    'https://source.unsplash.com/random/600x400',
    'https://source.unsplash.com/random/600x400',
  ];

  const [data, setData] = useState({
    title: 'Nombre del proyecto',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam efficitur in metus vel congue. Nulla efficitur nunc at vestibulum faucibus. Integer lobortis odio sed nisl rutrum sagittis. Phasellus hendrerit ligula ex, at egestas libero lacinia in. Maecenas interdum nec quam vel viverra. Pellentesque quis orci id nibh viverra posuere in ut justo. Nam molestie nulla ante, maximus placerat massa placerat id. Aliquam id sodales neque, nec dignissim nisl. Quisque eu gravida libero. Aliquam ac enim eget massa gravida aliquam. Suspendisse eget volutpat sem. Sed id augue laoreet, rhoncus lectus ac, lacinia nunc. Nulla vitae euismod arcu, quis egestas augue.',
    highlights,
  })
  const [isOpen, setIsOpen] = useState(false);
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);

  return (
    <Layout>
      <SEO title="Proyecto" />
      <SummaryBanner {...data}/>
      <div style={{margin: '2.5rem 0'}}>
    {/* Problema con new Image, no lo reconoce al hacer build
        <SmartGallery
          width={1280}
          height={800}
          images={images}
          onImageSelect={(event, src, index) => {setIsOpen(true); setActivePhotoIndex(index)}}
        />
    

        <ReactBnbGallery
          show={isOpen}
          photos={images}
          activePhotoIndex={activePhotoIndex}
          onClose={() => setIsOpen(false)}
        />
        */}
      </div>
      <Reviews/>
    </Layout>
  )
}

export default ProviderProjectDetail
